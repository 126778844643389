import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {
  faArrowUp,
  faPlus,
  faChevronRight,
  faTimes,
  faCheck,
  faArrowDown,
  faSave,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { OverlayService } from '@modules/overlay/services/overlay.service';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { debounceTime, filter } from 'rxjs/operators';
import { Message, Posting, PostingFilter, PostingNote, PostingService, UpdatePosting, User } from 'src/app/api/vat';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MessagesService } from '@modules/messages/services/messages.service';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
import { MatDialog } from '@angular/material/dialog';
import { AreYouSureDialogComponent } from '@modules/dialog/components/are-you-sure-dialog/are-you-sure-dialog.component';
import {
  CheckBoxService,
  PostingCheckbox,
} from '@modules/postings/services/check-box.service';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import { ActivatedRoute } from '@angular/router';
import { __values } from 'tslib';
import { PostingNoteChangeService } from '@modules/commons/services/posting-note-change.service';

export enum NOTEMODES {
  View = 1,
  Create = 2,
  Update = 3,
}

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit, OnDestroy {
  faArrowUp = faArrowUp;
  faPlus = faPlus;
  faChevronRight = faChevronRight;
  faTimes = faTimes;
  faCheck = faCheck;
  faArrowDown = faArrowDown;
  faSave = faSave;
  faTrash = faTrash;

  @Input() isAllPostingsSelected: boolean = false;
  @Input() totalPostings: number = 0;

  postingTabs: Posting[] = [];
  postingIds = this.postingTabs.map((x) => x.postingId);
  overlayState: number = 0;
  selectedTab: UntypedFormControl = new UntypedFormControl(0);
  selectedCheckboxPostingIdsCounter: number;
  private selectedCheckboxPostingIdList: number[];
  private deSelectedCheckboxPostingIdList: number[];
  postingFilter: PostingFilter;
  private editedNoteId: number = 0;
  private routePath: string;

  // Posting Notes Properties
  noteMode: NOTEMODES = NOTEMODES.View;
  public noteModeTypes = NOTEMODES;
  notesClosed: boolean = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  postingNoteFormControl = new UntypedFormGroup({
    personName: new UntypedFormControl(''),
    postingNoteSubject: new UntypedFormControl('', [Validators.maxLength(100)]),
    postingNoteText: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
  });
  allPersons: User[] = [];
  filteredPersons: User[] = [];
  Selectedpersons: User[] = [];
  currentNote?: PostingNote;
  savePostingLike: number = 1;

  createOrEditPostingNoteLabel: string;
  constructor(
    private overlayService: OverlayService,
    private postingService: PostingService,
    private internalMessagesServive: MessagesService,
    private internalCurrentCustomerService: InternalCurrentCustomerService,
    private internalFilterService: InternalFilterService,
    private checkBoxService: CheckBoxService,
    public dialog: MatDialog,
    private postingNoteChangeService: PostingNoteChangeService,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.overlayService.removeAllPostingTabs();
    this.overlayService.setOverlayClosed();
  }

  ngOnInit() {
    this.internalFilterService.postingFilter
      .pipe(
        filter((pf) => pf.analysisId !== null),
        debounceTime(2000)
      )
      .subscribe((postingFilter) => (this.postingFilter = postingFilter));

    this.checkBoxService.postingCheckboxs$.subscribe(
      (checkboxPosting: PostingCheckbox) => {
        this.isAllPostingsSelected = checkboxPosting.isAllSelected;
        this.deSelectedCheckboxPostingIdList = checkboxPosting.deSelected;

        if (this.isAllPostingsSelected) {
          this.selectedCheckboxPostingIdsCounter =
            this.totalPostings - this.deSelectedCheckboxPostingIdList.length;
        } else {
          this.selectedCheckboxPostingIdList = checkboxPosting.selected;
          this.selectedCheckboxPostingIdsCounter =
            checkboxPosting.selected.length;

          if (this.selectedCheckboxPostingIdsCounter === 0)
            this.savePostingLike = 1;
        }
      }
    );
    this.routePath = this.route.snapshot.routeConfig.path;
    if (this.routePath === 'posteringer/postingnote/:postingNoteId')
      this.route.url.subscribe((url) => {
        this.editedNoteId = Number(url[2].path);
      });

    this.overlayService.overlayState$.subscribe((overlayState) => {
      this.overlayState = overlayState;
    });

    this.overlayService.postingTabs$
      .pipe(filter((p) => p !== null))
      .subscribe((postingTabs: Posting[]) => {
        this.tryOpenSaveNoteDialog(this.postingTabs.length - 1).then(() => {
          this.postingTabs = postingTabs;
          this.selectedTab.setValue(this.postingTabs.length - 1);
        });
      });

    this.overlayService.currentSelectedPostingID$.subscribe((postingID) => {
      const index = this.postingTabs.map((p) => p.postingId).indexOf(postingID);
      if (index !== -1) {
        this.tryOpenSaveNoteDialog(index).then(() => {
          this.selectedTab.setValue(index);
        });
      }
    });

    this.loadUsers();

    this.getPostingNameControl().valueChanges.subscribe((value: string) => {
      if (this.getPostingNameControl().untouched && typeof value === 'string') {
        const selectedPersonsIds = this.Selectedpersons.map((p) => p.userId);
        this.filteredPersons = this.allPersons
          .filter(
            (o) =>
              o.userName
                ?.toLocaleLowerCase()
                .indexOf(value.toLocaleLowerCase()) > -1
          )
          .filter((p) => selectedPersonsIds.includes(p.userId) === false);
      } else {
        this.resetpersonNameFormControl();
      }
    });
  }

  public getPostingNameControl = () =>
    this.postingNoteFormControl.get('personName');
  public getPostingNoteSubjectControl = () =>
    this.postingNoteFormControl.get('postingNoteSubject');
  public getPostingNoteTextControl = () =>
    this.postingNoteFormControl.get('postingNoteText');

  private tryOpenSaveNoteDialog(postingTabIndex: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.isPostingNoteInformationEmpty()) {
        this.dialog
          .open(AreYouSureDialogComponent, {
            data: {
              title: 'Hvordan vil du fortsætte?',
              bodyInformation: 'Vil du gemme den igangværende posterings note?',
            },
          })
          .afterClosed()
          .subscribe((saveNote: boolean) => {
            if (saveNote)
              this.saveNote(this.postingTabs[this.selectedTab.value].postingId);

            this.selectedTab.setValue(postingTabIndex);
            this.noteMode = NOTEMODES.View;
            resolve();
          });
      } else {
        this.noteMode = NOTEMODES.View;
        resolve();
      }
    });
  }

  private isPostingNoteInformationEmpty = () =>
    (this.noteMode === NOTEMODES.Update &&
      (this.getPostingNameControl().touched ||
        this.getPostingNoteTextControl().touched ||
        this.getPostingNoteSubjectControl().touched)) ||
    (this.noteMode === NOTEMODES.Create &&
      this.getPostingNoteTextControl().valid);

  private loadUsers(): void {
    const customerid: number =
      +this.internalCurrentCustomerService.getCustomerId();
    this.postingService
      .getUsersForPostingNote(customerid)
      .subscribe((result) => {
        this.allPersons = result;
        this.filteredPersons = result;
      });
  }

  changeSelectedTap(event: MatTabChangeEvent): void {
    this.overlayService.updateCurrentPostingID(
      this.postingTabs[event.index]?.postingId
    );
  }

  openTab(): void {
    if (this.overlayState === 2) {
      this.overlayService.setOverlayFull();
    } else {
      this.overlayService.setOverlayMedium();
    }
  }

  private getTabIndex(posting: Posting): number {
    return this.postingTabs.map((s) => s.postingId).indexOf(posting.postingId);
  }

  public removeTab(posting: Posting) {
    const index = this.getTabIndex(posting);
    this.postingTabs.splice(index, 1);
    this.overlayService.updateCurrentPostingID(
      this.postingTabs[index - 1]?.postingId
    );
    if (this.postingTabs.length === 0) {
      this.overlayService.setOverlayClosed();
    }
  }

  public toggleMinOverlay(): void {
    this.overlayService.minOverlaySize();
  }

  public toggleMaxOverlay(): void {
    this.overlayService.maxOverlaySize();
  }

  createNote(): void {
    this.createOrEditPostingNoteLabel = 'Opret Note';
    this.clearNote();
    this.resetpersonNameFormControl();
    if (this.noteMode === 1) {
      this.noteMode = NOTEMODES.Create;
      this.notesClosed = false;
      if (this.overlayState === 0) {
        this.overlayService.setOverlayMedium();
      }
    } else {
      this.noteMode = NOTEMODES.View;
    }
  }

  clearNote(): void {
    this.getPostingNoteTextControl().reset('');
    this.getPostingNoteSubjectControl().reset('');
    this.Selectedpersons = [];
  }

  editNote(message: Message): void {
    this.createOrEditPostingNoteLabel = 'Rediger Note';
    this.noteMode = NOTEMODES.Update;
    this.loadPostingNote(message.postingNoteId);
  }

  closeNotes(): void {
    this.notesClosed = !this.notesClosed;
  }

  cancelNote(): void {
    this.noteMode = NOTEMODES.View;
    this.resetpersonNameFormControl();
  }

  private loadPostingNote(postingNoteId: number): void {
    this.postingService
      .postingNote(postingNoteId)
      .subscribe((result) => {
        this.currentNote = result;
        this.savePostingLike = 3;

        this.getPostingNoteTextControl().setValue(this.currentNote.text);
        this.getPostingNoteSubjectControl().setValue(this.currentNote.subject);
        this.Selectedpersons = this.allPersons.filter(
          (o) => result.mentionUserIds.indexOf(o.userId) > -1
        );
      });
  }

  saveNote(currentPostingId: number): void {
    const note: PostingNote = {
      postingIds: [currentPostingId],
      isNews: false,
      postingReferencesCount: 0,
      postingFilter: this.postingFilter,
      postingNoteId:
        this.noteMode === NOTEMODES.Create
          ? 0
          : this.currentNote?.postingNoteId,
      mentionUserIds: this.Selectedpersons.map((o) => o.userId),
      subject: this.getPostingNoteSubjectControl().value,
      text: this.getPostingNoteTextControl().value,
      createdBy:
        this.noteMode === NOTEMODES.Create ? null : this.currentNote.createdBy,
    };

    if (note.subject === '') {
      note.subject = 'Ny besked';
    }

    if (
      this.savePostingLike === 2 &&
      this.selectedCheckboxPostingIdsCounter > 0
    ) {
      note.postingIds = this.selectedCheckboxPostingIdList;
    }
    let noteId = this.editedNoteId;
    if (this.savePostingLike === 3) {
      this.deSelectedCheckboxPostingIdList = [];
      noteId = this.currentNote.postingNoteId;
      note.postingIds = [];
    }
    if (this.isAllPostingsSelected && this.savePostingLike == 2) {
      note.postingIds = [];
    }
    if (
      this.getPostingNoteSubjectControl().valid &&
      this.getPostingNoteTextControl().valid
    ) {
      const updatePosting: UpdatePosting = {
        postingFilter: this.postingFilter,
        postingNote: note,
        deSelectedPostings: this.deSelectedCheckboxPostingIdList,
        editedNoteId: noteId,
      };

      this.postingService
        .savePostingNote(updatePosting)
        .subscribe((isSaved: boolean) => {
          if (isSaved) {
            this.noteMode = NOTEMODES.View;
            this.clearNote();
            this.resetpersonNameFormControl();
            if (
              this.editedNoteId == note.postingNoteId &&
              this.savePostingLike !== 3
            ) {
              this.postingNoteChangeService.notifyNoteOnPostings();
            }
          }
        });
    }
  }

  removeAllTabs(): void {
    this.overlayService.resetOverayTabs();
  }

  removePerson(person: User): void {
    const index = this.Selectedpersons.indexOf(person);

    if (index >= 0) {
      this.Selectedpersons.splice(index, 1);
    }
    this.filteredPersons = this.allPersons.filter(
      (o) => !this.Selectedpersons.includes(o)
    );
  }

  clearTheMatChipInput(event: MatChipInputEvent): void {
    // Clear the input value
    event.chipInput.clear();
  }

  addSelectedPerson(userId: number, chipList: MatChipList): void {
    const user: User = this.allPersons.find((x) => x.userId === userId);
    chipList.writeValue('');
    chipList._setSelectionByValue('', false);
    this.Selectedpersons.push(user);

    this.resetpersonNameFormControl();
  }

  private resetpersonNameFormControl(): void {
    this.getPostingNameControl().markAsUntouched();
    this.getPostingNameControl().setValue('');
  }
}
