import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  faArrowRight,
  faTimes,
  faStickyNote,
  faComment,
  faArrowAltCircleUp,
  faEye,
  faExternalLinkAlt,
  faPen,
  faEnvelope,
  faUser,
  faCalendar,
  faExclamationCircle,
  faTrash,
  faArrowCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import { ExpandedMessageComponent } from '@modules/dialog/components/expanded-message/expanded-message.component';
import { ExportMessageComponent } from '@modules/dialog/components/export-message/export-message.component';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from '@modules/shell/services/menu.service';
import { OverlayService } from '@modules/overlay/services/overlay.service';
import { Customer, ExcelColumnItem, Message, MessageService, PostingNote, User, PostingService } from 'src/app/api/vat';
import { EventEmitter } from '@angular/core';

import { MessagesService } from '@modules/messages/services/messages.service';
import { LabelGlossaryService } from '@modules/label-glossary/services/label-glossary.service';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
import { InternalUserService } from '@modules/commons/services/internal-user.service';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NotifyService } from '@modules/shell/services/notify.service';
import { AreYouSureDialogComponent } from '@modules/dialog/components/are-you-sure-dialog/are-you-sure-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

export enum ComponentType {
  allmessageDialog = 'allmessageDialog',
  overlay = 'overlay',
  sidebar = 'sidebar',
  tableHover = 'tableHover',
}
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit, OnDestroy, OnChanges {
  // Icons
  faTimes = faTimes;
  faArrowRight = faArrowRight;
  faEnvelope = faEnvelope;
  faPen = faPen;
  faTrash = faTrash;
  faNewspaper = faNewspaper;
  faStickyNote = faStickyNote;
  faComment = faComment;
  faArrowAltCircleUp = faArrowAltCircleUp;
  faEye = faEye;
  faExternalLinkAlt = faExternalLinkAlt;
  faUser = faUser;
  faCalendar = faCalendar;
  faExclamationCircle = faExclamationCircle;
  faArrowCircleRight = faArrowCircleRight;

  subscriptions: Subscription[] = [];
  messageColletion: Message[] = [];
  @Input() postingId: number = undefined;
  @Input() searchValue: string = '';
  @Input() place: ComponentType;
  @Input() postingNoteId: number;
  @Input() postingNoteIds: number[] = [];
  @Output('edit') editClicked: EventEmitter<Message> =
    new EventEmitter<Message>();
  customer: Customer;
  @Output() messages: EventEmitter<Message[]> = new EventEmitter<Message[]>();
  loadAllMessages: boolean;
  loggedInUser: User;
  extraHoverText: boolean = false;
  constructor(
    public dialog: MatDialog,
    private menuService: MenuService,
    private messageService: MessageService,
    private overlayService: OverlayService,
    private notifyService: NotifyService,
    private messagesServiceInternal: MessagesService,
    private labelGlossaryService: LabelGlossaryService,
    private internalCustomerService: InternalCurrentCustomerService,
    private internalUserService: InternalUserService,
    private postingService: PostingService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.loadAllMessages = false;

    this.internalUserService.user$.subscribe((user) => {
      this.loggedInUser = user;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('searchValue' in changes) {
      this.loadMessages();
    }
  }

  get mailEnabled(): boolean {
    return this.customer.customerConfig?.mailEnable ?? false;
  }

  permissionToEdit(item: PostingNote): boolean {
    return item.createdBy.userId === this.loggedInUser?.userId;
  }

  ngOnInit() {
    this.loadMessages();
    this.customer = this.internalCustomerService.getCustomer();
  }

  private loadMessages() {
    this.subscriptions.push(
      this.messagesServiceInternal.MessageList$.subscribe((messages) => {
        switch (this.place) {
          case ComponentType.allmessageDialog:
            if (this.searchValue === '') {
              this.messageColletion = messages;
            } else {
              this.messageColletion = messages.filter(
                (o) =>
                  (
                    'id' +
                    o.postingNoteId +
                    ' ' +
                    o.subject.toLocaleLowerCase() +
                    ' ' +
                    o.text.toLocaleLowerCase() +
                    ' ' +
                    (o.createdBy.userName.toLocaleLowerCase() ?? '')
                  ).indexOf(this.searchValue?.toLocaleLowerCase()) > -1
              );
            }
            this.messages.emit(this.messageColletion);
            break;

          case ComponentType.overlay:
            this.subscriptions.push(
              this.messageService
                .getAllPostingNotesByPostingId(this.postingId)
                .pipe(take(1))
                .subscribe((postingNotes) => {
                  this.messageColletion = postingNotes;
                  this.messages.emit(this.messageColletion);
                  this.overlayService.annoucePostinghasRelatedNotes(
                    postingNotes.map((p) => p.postingNoteId)
                  );
                })
            );
            break;

          case ComponentType.tableHover:
            this.messageColletion = messages.filter(
              (o) => o.postingNoteId === this.postingNoteIds[0]
            );

            if (this.postingNoteIds.length > 1) {
              this.extraHoverText = true;
            }
            break;

          case ComponentType.sidebar:
            this.messageColletion = messages.filter(
              (o) =>
                (o.noteForMe && o.readDate === null) ||
                (!this.isPostingNote(o) && o.readDate === null)
            );
            this.messages.emit(this.messageColletion);
            this.messagesServiceInternal.setNewMessagesCount(
              this.messageColletion.length
            );
            break;

          default:
            throw console.error(
              'Need to set a place on the input for the app-message to work '
            );
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  closeMessage(item: Message) {
    this.messagesServiceInternal.setMessageAsRead(item);
  }

  reopenMessage(item: Message) {
    this.messagesServiceInternal.setMessageAsUnRead(item);
  }

  openExpandedMessageDialog(item: Message): void {
    const dialogRef = this.dialog.open(ExpandedMessageComponent, {
      width: '900px',
      data: item,
      autoFocus: false,
    });
  }

  goToPosting(item: Message) {
    this.menuService.toggleSidebarState();
    this.overlayService.setOverlayMedium();
    this.messagesServiceInternal.setSelectedMessage(item.postingNoteId);
  }

  editNote(item: Message) {
    this.editClicked.emit(item);
  }

  deleteNote(item: Message) {
    this.subscriptions.push(
      this.postingService
        .deletePostingNote(item.postingNoteId)
        .subscribe((result) => {
          if (result) {
            this.notifyService.notifySnackBarInformation(
              `Posteringsnoten er nu slettet.`,
              'green'
            );
            this.redirectToDashboardIfNeccessary(item.postingNoteId);
          } else {
            this.notifyService.notifySnackBarInformation(
              `Du har ikke tilladelse til at slette denne posteringsnote eller posteringsnoten eksisterer ikke.`,
              'red'
            );
          }
        })
    );
  }
  redirectToDashboardIfNeccessary(postingNoteId: number) {
    if (
      this.route.snapshot.routeConfig.path.includes(
        'posteringer/postingnote/'
      ) &&
      this.route.snapshot.params['postingNoteId'] == postingNoteId
    ) {
      this.router.navigate(['dashboard']);
    } else {
      this.loadMessages();
    }
  }

  openDeleteConfirmation(item: Message) {
    let title =
      'Er du sikker på, at du vil slette denne posteringsnote? \n ID' +
      item.postingNoteId +
      ' - ' +
      item.subject;
    let bodyInfo = item.text;
    this.dialog
      .open(AreYouSureDialogComponent, {
        data: {
          title: title,
          bodyInformation: bodyInfo,
        },
      })
      .afterClosed()
      .subscribe((saveNote: boolean) => {
        if (saveNote) {
          this.deleteNote(item);
        }
      });
  }

  eksportNote(item: Message) {
    const excelColumnItem: ExcelColumnItem[] =
      this.labelGlossaryService.getExcelColumns();

    const dialogRef = this.dialog.open(ExportMessageComponent, {
      width: '900px',
      data: item,
      autoFocus: false,
    });
  }

  isPostingNote(item: Message): boolean {
    return item.isNews === false;
  }
}
